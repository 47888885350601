export default class Article {
  constructor(variationId, position) {
    this.variationId = variationId;
    this.position = position;
  }

  getLoadTrackingPayloadForParentAndStatus(parentId) {
    return {
      id: `${parentId}-Prod${this.position}`,
      parentId,
      name: "ComboListDetail-Product",
      position: this.position,
      status: "loaded",
      variationId: this.variationId,
      labels: {},
    };
  }

  getVisibleTrackingPayloadForParentAndStatus(parentId) {
    return {
      id: `${parentId}-Prod${this.position}`,
      parentId,
      name: "ComboListDetail-Product",
      position: this.position,
      status: "visible",
      variationId: this.variationId,
      labels: {},
    };
  }

  getClickTrackingPayloadForParentAndStatus(parentId) {
    return {
      id: `${parentId}-Prod${this.position}`,
      parentId,
      name: "ComboListDetail-Product",
      position: this.position,
      status: "clicked",
      variationId: this.variationId,
      labels: {},
    };
  }
}
