import ComboCombolist from "./combolist";

window.o_combo_combolist = window.o_combo_combolist || [];

/*                                                    */
export function initCinema() {
  const containers = document.querySelectorAll(".combo_combolist-container");
  Array.from(containers).forEach((container) => {
    if (container.getAttribute("data-tracked") !== "true") {
      container.setAttribute("data-tracked", "true");
      const combolist = new ComboCombolist(container);
      combolist.addEventQBusListeners();
      combolist.addClickEventListenersForComboMoodImage();
      combolist.addClickEventListenersForArticles();
      combolist.initCinema();
      window.o_combo_combolist.push(combolist);
    }
  });
}
