export default class Combolist {
  constructor(combos, position, featureNumber, promoType, sorting, masLabels) {
    const buildType = (pType) => {
      const promoTypeToCombolistType = {
        ComboListDetailOutfit: "OUTFIT",
        ComboListDetailRoom: "ROOMS",
      };
      return promoTypeToCombolistType[pType];
    };

    this.combos = combos;
    this.position = position;
    this.featureNumber = featureNumber;
    this.promoType = promoType;
    this.type = buildType(promoType);
    this.sorting = sorting;
    this.masLabels = masLabels;
  }

  getTrackingPayload(status) {
    const trackingPayload = {
      id: `combolist${this.featureNumber}`,
      name: "ComboListDetail",
      position: this.position,
      status,
      labels: {
        promo_ComboType: [this.type],
        promo_Feature: ["true"],
        promo_FilledSlots: [`${this.combos.length}`],
        promo_FeatureNumber: [`${this.featureNumber}`],
        ...this.masLabels,
      },
    };
    if (this.sorting !== undefined && this.sorting !== null) {
      trackingPayload.labels.promo_ComboSorting = [this.sorting];
    }
    return trackingPayload;
  }
}
