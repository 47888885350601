import { initCinema } from "./init";
import Logger from "./logger";
import "../scss/all.scss";

/*                             */
class ft3BcnCombolist extends HTMLElement {
  /*                                  */
  connectedCallback() {
    Logger.group("[FT3BCN combo combolist]");
    Logger.count("Combolist Invocation");
    initCinema();
    Logger.groupEnd();
  }
}

customElements.define("ft3bcn-combolist", ft3BcnCombolist);
