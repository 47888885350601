export default class Combo {
  constructor(id, isVisible, comboSource, contentSource, articles, position) {
    this.id = id;
    this.isVisible = isVisible;
    this.comboSource = comboSource;
    this.contentSource = contentSource;
    this.articles = articles;
    this.position = position;
  }

  getLoadTrackingPayloadForParent(parentId) {
    return {
      id: `${parentId}-Combo${this.position}`,
      parentId,
      name: "ComboListDetail-Tile",
      position: this.position,
      status: "loaded",
      labels: {
        promo_Content: [this.id],
        promo_ComboSource: [this.comboSource],
        promo_Source: [this.contentSource],
      },
    };
  }

  getVisibleTrackingPayloadForParent(parentId) {
    return {
      id: `${parentId}-Combo${this.position}`,
      parentId,
      name: "ComboListDetail-Tile",
      position: this.position,
      status: "visible",
      labels: {
        promo_Content: [this.id],
        promo_ComboSource: [this.comboSource],
        promo_Source: [this.contentSource],
      },
    };
  }

  getClickTrackingPayloadForParent(parentId) {
    return {
      id: `${parentId}-Combo${this.position}`,
      parentId,
      name: "ComboListDetail-Tile",
      position: this.position,
      status: "clicked",
      labels: {
        promo_Content: [this.id],
        promo_ComboSource: [this.comboSource],
        promo_Source: [this.contentSource],
      },
    };
  }
}
