/*                                  */
import Combolist from "./domain/combolist";
import Combo from "./domain/combo";
import Article from "./domain/article";
import {
  sendArticleClickTrackingEvent,
  sendComboClickTrackingEvent,
  sendLoadTrackingEvent,
  sendScrollRightOrLeftTrackingEvent,
  sendVisibleTrackingEvent,
} from "./tracking_service";

const DEFAULT_WIDGET_POSITION = "1";

export default class ComboCombolist {
  constructor(container) {
    this.container = container;
    this.id = container.querySelector(".combo_combolist-cinema").id;
    this.featureOrder = this.getFeatureOrder();
    this.featurePosition = this.getPosition();
    this.featureNumber = this.getFeatureNumber();
    this.promoType = container.getAttribute("data-promoType");
    this.sorting = container.getAttribute("data-sorting");

    const combos = Array.prototype.map.call(
      this._getComboItems(),
      (comboHtml, index) => {
        const comboId = comboHtml.getAttribute("data-combo-id");
        const comboSource = comboHtml.getAttribute("data-comboSource");
        const contentSource = comboHtml.getAttribute("data-combo-model");
        const articleElements = Array.from(
          comboHtml.querySelectorAll(".combo_combolist-article"),
        );
        const articles = [].map.call(
          articleElements,
          (articleHtml, articleIndex) => {
            return new Article(
              articleHtml.getAttribute("data-variation-id"),
              articleIndex + 1,
            );
          },
        );
        return new Combo(
          comboId,
          false,
          comboSource,
          contentSource,
          articles,
          index + 1,
        );
      },
    );

    function _getMasFeatureTrackingLabels(combolistContainer) {
      return JSON.parse(
        combolistContainer.parentElement.parentElement.getAttribute(
          "data-feature-tracking-labels",
        ),
      );
    }

    this.combolist = new Combolist(
      combos,
      this.featurePosition,
      this.featureNumber,
      this.promoType,
      this.sorting,
      _getMasFeatureTrackingLabels(container),
    );

    this.threshold = this.promoType === "ComboListDetailOutfit" ? 0.35 : 0.5;

    this.observer = new IntersectionObserver(
      (observerEntries, intersectionObserver) => {
        this.intersectionObserverCallback(
          observerEntries,
          intersectionObserver,
          this,
        );
      },
      {
        threshold: this.threshold,
      },
    );

    this._getComboItems().forEach((combo) => {
      if (combo) {
        this.observer.observe(combo);
      }
    });
  }

  initCinema() {
    /*                                                                                                                             */
    /*                                                             */
    setTimeout(() => {
      window.o_global.eventQBus.emit(
        "pattern.carousel.init",
        ".combo_combolist-cinema",
      );
    }, 300);
  }

  addEventQBusListeners() {
    window.o_global.eventQBus.on(
      "pattern.carousel.mounted",
      ({ element, currentSlides }) => {
        if (this.id === element.id) {
          this._setVisibleSlides(currentSlides);
          sendLoadTrackingEvent(this.combolist, currentSlides);
        }
      },
    );
    window.o_global.eventQBus.on(
      "pattern.carousel.stage.changed",
      ({ element, currentSlides, scrollingRight }) => {
        if (this.id === element.id) {
          this._resetVisibleSlides();
          this._setVisibleSlides(currentSlides);
          if (scrollingRight === true) {
            sendScrollRightOrLeftTrackingEvent(
              this.combolist,
              this.featureOrder,
              currentSlides,
              "next",
            );
          } else {
            sendScrollRightOrLeftTrackingEvent(
              this.combolist,
              this.featureOrder,
              currentSlides,
              "previous",
            );
          }
        }
      },
    );
  }

  _resetVisibleSlides() {
    Array.from(this.combolist.combos).forEach((combo) => {
      combo.isVisible = false; /*                                    */
    });
  }

  _setVisibleSlides(currentSlides) {
    if (this.combolist && this.combolist.combos) {
      for (let step = 0; step < currentSlides.length; step += 1) {
        const combo = this.combolist.combos[currentSlides[step]];
        if (combo) {
          combo.isVisible = true;
        }
      }
    }
  }

  _setComboAsVisible(comboid) {
    this.combolist.combos.filter((combo) => combo.id === comboid)[0].isVisible =
      true;
  }

  intersectionObserverCallback(entries, interSectionObserver, combolist) {
    if (entries.filter((entry) => entry.isIntersecting).length > 0) {
      this._resetVisibleSlides();
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          combolist._setComboAsVisible(
            entry.target.getAttribute("data-combo-id"),
          );
        }
      });
      interSectionObserver.disconnect();
      sendVisibleTrackingEvent(this.combolist);
    }
  }

  addClickEventListenersForComboMoodImage() {
    const links = this.container.querySelectorAll(
      ".combo_combolist__mood-image-container a",
    );
    Array.from(links).forEach((moodImage) => {
      moodImage.addEventListener("click", (event) => {
        const comboId = this.getComboId(event.currentTarget);
        this.trackComboClick(comboId);
      });
    });
  }

  addClickEventListenersForArticles() {
    const articles = this.container.querySelectorAll(
      ".combo_combolist-article a",
    );
    Array.from(articles).forEach((article) => {
      article.addEventListener("click", (event) => {
        const variationId = event.currentTarget
          .closest(".combo_combolist-article")
          .getAttribute("data-variation-id");
        const comboId = this.getComboId(event.currentTarget);
        this.trackArticleClick(comboId, variationId);
      });
    });
  }

  trackComboClick(comboId) {
    sendComboClickTrackingEvent(
      this.combolist,
      [].find.call(this.combolist.combos, (combo) => combo.id === comboId),
    );
  }

  trackArticleClick(comboId, variationId) {
    const clickedCombo = [].find.call(
      this.combolist.combos,
      (combo) => combo.id === comboId,
    );
    const clickedArticle = [].find.call(
      clickedCombo.articles,
      (article) => article.variationId === variationId,
    );
    sendArticleClickTrackingEvent(this.combolist, clickedCombo, clickedArticle);
  }

  isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  getComboId(clickableItem) {
    const listElement = clickableItem.closest(".combo_combolist-combo");
    return listElement.getAttribute("data-combo-id");
  }

  getPosition() {
    const featureOrder =
      this.container.parentElement.parentElement.getAttribute(
        "data-feature-order",
      );
    return parseInt(featureOrder || DEFAULT_WIDGET_POSITION, 10);
  }

  getFeatureNumber() {
    const featurePosition = this.getPosition();
    const combolistWidgets = document.querySelectorAll(
      ".combo_combolist-container",
    );
    return (
      Array.from(combolistWidgets).findIndex((widgetDiv) => {
        const featureOrder =
          widgetDiv.parentElement.parentElement.getAttribute(
            "data-feature-order",
          );
        return (
          parseInt(featureOrder || DEFAULT_WIDGET_POSITION, 10) ===
          featurePosition
        );
      }) + 1
    );
  }

  getFeatureOrder() {
    const featurePosition = this.getPosition();
    const featureNumber = this.getFeatureNumber();
    return `${featurePosition}$${featureNumber}`;
  }

  getAllComboIds() {
    const comboItems = this._getComboItems();

    return Array.from(comboItems).map((div) =>
      div.getAttribute("data-combo-id"),
    );
  }

  _getComboItems() {
    return this.container.querySelectorAll(".combo_combolist-combo");
  }
}
